import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const sendRequestSubscription = createAsyncThunk(
  'auth/sendRequestSubscription',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('subscriptions/user/request')

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
