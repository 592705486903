import styled from '@emotion/styled'
import { Colors } from 'constants/colors'

export const PrivacyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  margin: 20px 0;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;

  color: ${Colors.textColor};
`

export const PolicyBtn = styled.button`
  background-color: none;
  border: none;

  text-decoration: underline;
  color: ${Colors.blueAccent};
`
