import { ToothType, User } from '../auth.state'

import { ILanguage } from 'components/TranslatorProvider/TranslatorProvider.types'
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface Credentials {
  toothType?: ToothType
  language?: ILanguage
}

export const changeAccountSettings = createAsyncThunk<User, Credentials>(
  'auth/change-account-settings',
  async (data, thunkAPI) => {
    try {
      const response = await axios.patch('/user', data)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
