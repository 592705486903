import {
  PasswordIcon,
  StelydFormHelperText,
  StyledTextField,
  TextFieldBox,
} from './InputField.styled'
import React, { CSSProperties, useState } from 'react'
import { animated, useSpring } from 'react-spring'

import { Colors } from 'constants/colors'
import { FieldInputProps } from 'formik'
import { Icon } from 'components/Icon'
import { InputAdornment } from '@mui/material'

interface FormikTextFieldProps {
  label?: string
  type?: string
  fullWidth?: boolean
  fieldProps: FieldInputProps<string>
  setFieldValue?: (value: string | number) => void
  error?
  touched?
  className?: string
  style?: CSSProperties
  placeholder?: string
  disabled?: boolean
  multiline?: boolean
  minRows?: number
  bottom?: number
  endIcon?: boolean
  iconName?: string
  symbol?: string
}

const InputField: React.FC<FormikTextFieldProps> = ({
  label,
  type = 'text',
  fullWidth = true,
  fieldProps,
  error,
  touched,
  className,
  style,
  placeholder,
  disabled = false,
  multiline = false,
  minRows,
  bottom,
  setFieldValue,
  endIcon = false,
  iconName,
  symbol = '',
}) => {
  const [passwordType, setPasswordType] = useState('password')
  const handleChangePasswordType = () => {
    passwordType === 'password' ? setPasswordType('text') : setPasswordType('password')
  }

  const handleChange = (e) => {
    if (symbol !== '' && setFieldValue) {
      setFieldValue(e.target.value.replace(symbol, ''))
    } else {
      fieldProps.onChange(e)
    }
  }

  const errorSpring = useSpring({
    opacity: touched && error ? 1 : 0, // Плавное изменение прозрачности
    transform: touched && error ? 'translateY(0)' : 'translateY(-20px)', // Плавное "выдвижение" из-под инпута
    config: { duration: 350 }, // Конфигурация анимации, например, длительность
  })

  return (
    <TextFieldBox
      style={{ width: '100%', marginBottom: `${bottom ? `${bottom}px` : '20px'}`, ...style }}
    >
      <StyledTextField
        style={{ ...style }}
        className={className}
        fullWidth={fullWidth}
        multiline={multiline}
        minRows={minRows}
        maxRows={minRows}
        placeholder={placeholder}
        type={type === 'password' ? passwordType : type}
        label={label}
        {...fieldProps}
        error={touched && !!error}
        // helperText={touched ? error : ''}
        disabled={disabled}
        value={fieldProps.value + symbol}
        onChange={handleChange}
        InputProps={{
          startAdornment:
            error && touched ? (
              <InputAdornment position='start'>
                <Icon name='alertCircle' color={Colors.errorColor} />
              </InputAdornment>
            ) : undefined,
          endAdornment:
            type === 'password' || endIcon ? (
              type === 'password' ? (
                <InputAdornment position='end'>
                  {passwordType === 'password' ? (
                    <PasswordIcon name='closeEye' onClick={handleChangePasswordType} />
                  ) : (
                    <PasswordIcon name='openEye' onClick={handleChangePasswordType} />
                  )}
                </InputAdornment>
              ) : (
                <InputAdornment sx={{ width: '30px' }} position='end'>
                  <Icon name={iconName} size={24} color={Colors.blueAccent} />
                </InputAdornment>
              )
            ) : undefined,
        }}
      />
      {touched && error && (
        <animated.div style={errorSpring}>
          <StelydFormHelperText error>{error}</StelydFormHelperText>
        </animated.div>
      )}
    </TextFieldBox>
  )
}

export default InputField
