import { Trans } from '@lingui/macro'
import {
  List,
  PolicyButton,
  ScrollPolicyBox,
  Subtitle,
  Text,
  Title,
  ListItem,
  Span,
  MailLink,
} from './PrivacyPolicyInfo.styled'
import { FC } from 'react'

interface IPrivacyPolicyInfo {
  onClick: () => void
}

const PrivacyPolicyInfo: FC<IPrivacyPolicyInfo> = ({ onClick }) => {
  return (
    <ScrollPolicyBox>
      <Title>Privacy policy</Title>
      <Subtitle>Introduction</Subtitle>
      <Text>
        Welcome to Treaco.online, the website and web application operated by Treaco. We are
        committed to protecting and respecting your privacy. This Privacy Policy explains how we
        collect, use, disclose, and safeguard your information when you visit our website and use
        our application.
      </Text>
      <Subtitle>1. What Information Do We Collect?</Subtitle>
      <Text>Personal Data: We collect various types of personal data, including:</Text>
      <List>
        <ListItem>
          <p>
            {' '}
            Identification and contact information (names, email addresses, phone numbers, birth
            numbers)
          </p>
        </ListItem>
        <ListItem>
          <p>
            Information related to patient requests (symptoms, health status, appointment details)
          </p>
        </ListItem>
        <ListItem>
          <p>Browsing habits and usage data</p>
        </ListItem>
        <ListItem>
          <p>Data about healthcare providers’ use of the application</p>
        </ListItem>
        <ListItem>
          <p>Data for statistical and analytical purposes</p>
        </ListItem>
        <ListItem>
          <p>Payment information if services involve financial transactions</p>
        </ListItem>
      </List>
      <Subtitle>2. How Do We Process Your Information?</Subtitle>
      <Text>We process your personal data for the following purposes:</Text>
      <List>
        <ListItem>
          <p>Facilitating communication between patients and healthcare providers</p>
        </ListItem>
        <ListItem>
          <p>Scheduling and managing patient requests</p>
        </ListItem>
        <ListItem>
          <p>Improving and ensuring the functionality of the application</p>
        </ListItem>
        <ListItem>
          <p>Conducting statistical analysis and enhancing the application</p>
        </ListItem>
        <ListItem>
          <p>Marketing and promoting the application and related services</p>
        </ListItem>
      </List>
      <Subtitle>3. Legal Bases for Processing</Subtitle>
      <Text>
        We only process your personal data when we have a valid legal reason to do so. This
        includes:
      </Text>
      <List>
        <ListItem>
          <p>Your consent</p>
        </ListItem>
        <ListItem>
          <p>Performance of a contract with you</p>
        </ListItem>
        <ListItem>
          <p>Compliance with a legal obligation</p>
        </ListItem>
        <ListItem>
          <p>
            Our legitimate business interests, provided your interests and fundamental rights do not
            override those interests
          </p>
        </ListItem>
      </List>
      <Subtitle>4. How Do We Obtain Consent?</Subtitle>
      <Text>
        Consent is obtained during the registration process, where users agree to the terms and
        conditions, including this Privacy Policy. Explicit consent is obtained for processing
        sensitive data, such as health information.
      </Text>
      <Subtitle>5. What Are Your Rights?</Subtitle>
      <Text>Under GDPR, you have the following rights regarding your data:</Text>
      <List>
        <ListItem>
          <p>
            <Span>Access:</Span> You can request access to your personal data.
          </p>
        </ListItem>
        <ListItem>
          <p>
            <Span>Correction:</Span> You can request correction or update of your data.
          </p>
        </ListItem>
        <ListItem>
          <p>
            <Span>Deletion:</Span> You can request deletion of your data.
          </p>
        </ListItem>
        <ListItem>
          <p>
            <Span>Objection:</Span> You can object to or restrict the processing of your data.
          </p>
        </ListItem>
        <ListItem>
          <p>
            {' '}
            <Span>Data Portability:</Span> You can request the transfer of your data to another
            service provider.
          </p>
        </ListItem>
        <ListItem>
          <p>
            <Span>Withdraw Consent:</Span> You can withdraw your consent at any time.
          </p>
        </ListItem>
      </List>
      <Text>
        To exercise these rights, please contact our Data Protection Officer (DPO) at [DPO Email
        Address].
      </Text>
      <Subtitle>6. Data Sharing and Third Parties</Subtitle>
      <Text>
        We may share your data with third parties, particularly contracted data processors who help
        manage and operate the application. This includes entities like Smartlook.com for technical
        problem analysis and application improvement. All data sharing is done under strict
        contracts ensuring compliance with GDPR and other relevant data protection laws.
      </Text>
      <Subtitle>7. Data Security Measures</Subtitle>
      <Text>We implement robust security measures to protect your data, including:</Text>
      <List>
        <ListItem>
          <p>Secure data centers with ISO 27001 certification</p>
        </ListItem>
        <ListItem>
          <p>Encryption of data during transmission</p>
        </ListItem>
        <ListItem>
          <p>Strong password policies and password encryption</p>
        </ListItem>
        <ListItem>
          <p>Regular automatic backups</p>
        </ListItem>
        <ListItem>
          <p>Internal policies on data protection and access control</p>
        </ListItem>
        <ListItem>
          <p>Regular security reviews and updates</p>
        </ListItem>
      </List>
      <Subtitle>8. Policy Updates and User Notification</Subtitle>
      <Text>
        We may update this Privacy Policy from time to time. Users will be informed about
        significant changes through notifications on the website, application, and via email. The
        updated policies will always be available on our website for review.
      </Text>
      <Subtitle>9. Contact Information</Subtitle>
      <Text>
        If you have any questions or concerns about this Privacy Policy or our data practices,
        please contact us at
        <MailLink href='mailto:contact@treaco.online'>contact@treaco.online</MailLink> .
      </Text>
      <PolicyButton onClick={onClick} color='blue'>
        <Trans id='3qDtPc6vz2uUt83hfx5ii2-auth'>I agree</Trans>
      </PolicyButton>
    </ScrollPolicyBox>
  )
}

export default PrivacyPolicyInfo
