import { User } from '../auth.state'
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface Credentials {
  clinic: string
  password: string
}

interface IResCompleteRegistration {
  message: string
  user: User
}

export const completeRegistration = createAsyncThunk<IResCompleteRegistration, Credentials>(
  'auth/completeRegistration',
  async (credentials, thunkAPI) => {
    try {
      const response = await axios.post('/auth/registration', credentials)

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
