import { LangBox, StyledFormControl, StyledSelect } from './LanguagesSelector.styled'
import { MenuItem, SelectChangeEvent } from '@mui/material'

import { Colors } from 'constants/colors'
import { FC } from 'react'
import { ILanguage } from 'components/TranslatorProvider/TranslatorProvider.types'
import { Icon } from 'components/Icon'
import { TranslatorContext } from 'components/TranslatorProvider/TranslatorProvider'
import { changeAccountSettings } from 'store/auth/operations/change-account-settings.operation'
import { useAppDispatch } from 'store/hooks'
import { useContextSelector } from 'use-context-selector'

interface Props {
  className?: string
}

const CustomExpandMore = ({ ...rest }) => {
  return <Icon name='arrow-down' {...rest} color={Colors.blueAccent} />
}

const LanguagesSelector: FC<Props> = () => {
  const currentLanguage = useContextSelector(TranslatorContext, (state) => state.language)
  const setCurrentLanguage = useContextSelector(TranslatorContext, (state) => state.loadLocale)
  const languages = useContextSelector(TranslatorContext, (state) => state.languages)
  const dispatch = useAppDispatch()
  const onChange = (event: SelectChangeEvent<ILanguage>) => {
    const lang = event.target.value as ILanguage
    setCurrentLanguage(lang)
    dispatch(changeAccountSettings({ language: lang }))
  }

  return (
    <>
      <StyledFormControl>
        <StyledSelect
          sx={{
            display: 'flex',
            '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.blueAccent,
            },
            '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.blueAccent,
            },
            '.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.errorColor,
            },
          }}
          IconComponent={CustomExpandMore}
          onChange={onChange}
          value={currentLanguage}
          MenuProps={{
            PaperProps: {
              style: {
                marginTop: 5,
                borderRadius: 20,
                borderColor: Colors.blueLight,
                borderWidth: 1,
                backgroundColor: Colors.darkWhite,
              },
            },
          }}
        >
          {(languages || []).map((el) => (
            <MenuItem
              sx={{
                display: 'flex',
                '&.Mui-selected': {
                  backgroundColor: 'inherit',
                  color: Colors.blueAccent,
                  '&:hover': {
                    backgroundColor: 'inherit',
                  },
                },
                '&:hover': {
                  backgroundColor: 'inherit',
                },
              }}
              key={el.value}
              value={el.value}
            >
              <LangBox>
                <Icon name={el.icon} size={24} />
                {el.name}
              </LangBox>
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </>
  )
}

export default LanguagesSelector
