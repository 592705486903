import './helpers/interceptors/response.interceptors'

import { persistor, store } from 'store'

import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Icons } from 'components/Icons'
// import { I18nProvider } from '@lingui/react'
// import { i18n } from '@lingui/core'
// import { messages } from './locales/en/messages'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import { TranslatorProvider } from 'components/TranslatorProvider'

// i18n.load('en', messages)
// i18n.activate('en')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <TranslatorProvider>
        <PersistGate loading={null} persistor={persistor}>
          <Icons />
          <App />
        </PersistGate>
      </TranslatorProvider>
    </Provider>
  </BrowserRouter>,
)
