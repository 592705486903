import styled from '@emotion/styled'
import { Box, FormHelperText, TextField } from '@mui/material'
import { Icon } from 'components/Icon'
import { Colors } from 'constants/colors'

export const TextFieldBox = styled(Box)``

export const StyledTextField = styled(TextField)`
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);

  .MuiOutlinedInput-root {
    line-height: 56px;

    fieldset {
      border-color: ${Colors.blueLight};
      border-radius: 12px;
      transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover fieldset {
      border-color: ${Colors.blueAccent};
    }

    &.Mui-focused fieldset {
      border-color: ${Colors.blueAccent};
    }

    &.Mui-shrink fieldset {
      border-color: ${Colors.blueLight};
    }

    &.Mui-error fieldset {
      border-color: ${Colors.errorColor};
      /* font-family: Rubik; */
    }
  }

  .MuiInputBase-input {
    color: ${Colors.textColor};

    padding: 12px 0 12px 16px;

    font-family: Rubik;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: 'transparent';
    }

    &::-webkit-scrollbar-thumb {
      background: ${Colors.infoBgColor}; // Цвет самого скроллбара (ползунка)
      border-radius: 20px;
    }
  }

  .MuiInputBase-input::placeholder {
    color: ${Colors.black};
    font-family: Rubik;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    opacity: 50%;
  }

  .MuiInputLabel-root {
    color: ${Colors.textColor};
    font-family: Rubik;
    font-weight: 400;
    padding-bottom: 20px;
    transform: translateY(12px) translateX(14px);
  }

  .MuiInputLabel-root.MuiInputLabel-shrink {
    color: ${Colors.black};
    transform: translateY(-9px) translateX(14px);
    font-size: 12px;
  }

  .Mui-error.MuiInputLabel-shrink {
    color: ${Colors.errorColor}; // Цвет label при ошибке
  }

  .MuiInputLabel-root.Mui-focused {
    color: ${Colors.blueAccent};
    transform: translateY(-9px) translateX(14px);
    font-size: 12px;
  }

  .Mui-error.Mui-focused {
    color: ${Colors.errorColor}; // Цвет label при ошибке
  }

  .MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-right: 0;
  }
`
export const PasswordIcon = styled(Icon)`
  color: ${Colors.black};

  :hover,
  :focus {
    color: ${Colors.blueAccent};
  }

  cursor: pointer;
`
export const StelydFormHelperText = styled(FormHelperText)`
  margin-top: 0;
  padding: 2px 8px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  border-radius: 4px;

  background-color: ${Colors.errorColor};
  color: ${Colors.white} !important;
  font-family: Rubik;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px / 12px;
`
