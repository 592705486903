import { FC, useMemo } from 'react'

import { ISubscription } from 'store/auth/auth.state'
import { Trans } from '@lingui/macro'

interface ISubscriptionStatusProps {
  subscription: ISubscription
  isLoading: boolean
}

const SubscriptionStatus: FC<ISubscriptionStatusProps> = ({ subscription, isLoading }) => {
  const { dateLeft, type } = useMemo(() => {
    if (!subscription)
      return {
        dateLeft: 0,
        type: 'demo',
      }

    const now = new Date()
    const end = new Date(subscription.endAt)

    const dateLeft = Math.ceil((end.getTime() - now.getTime()) / (1000 * 60 * 60 * 24))
    return { dateLeft, type: subscription.type }
  }, [subscription])

  if (isLoading) return null

  const switchSubscription = () => {
    if (type === 'demo') {
      if (dateLeft <= 0) {
        return (
          <div className='px-[12px] py-[8px] rounded-t-[16px] rounded-br-[16px] bg-blue-100'>
            <p className='font-rubik text-[12px] leading-[16px] text-blue'>
              <Trans id='m42qdFSN6WgrPr2c9yXWwg-header'>Demo access has ended</Trans>
            </p>
          </div>
        )
      } else {
        return (
          <div className='px-[12px] py-[8px] rounded-t-[16px] rounded-br-[16px] bg-blue-100'>
            <p className='font-rubik text-[12px] leading-[16px] text-blue'>
              {dateLeft}{' '}
              <span>
                <Trans id='fEzXyhxjNYXceM4vw8CKAN-header'>days until the end of demo access</Trans>
              </span>
            </p>
          </div>
        )
      }
    } else {
      if (dateLeft <= 0) {
        return (
          <div className='px-[12px] py-[8px] rounded-t-[16px] rounded-br-[16px] bg-blue-100'>
            <p className='font-rubik text-[12px] leading-[16px] text-blue'>
              <Trans id='kG2VjBgydwhGtwLnByKZfu-header'>Paid access has ended</Trans>
            </p>
          </div>
        )
      } else if (dateLeft > 0 && dateLeft <= 5) {
        return (
          <div className='px-[12px] py-[8px] rounded-t-[16px] rounded-br-[16px] bg-blue-100'>
            <p className='font-rubik text-[12px] leading-[16px] text-blue'>
              {dateLeft}{' '}
              <span>
                <Trans id='kQsKaoeuAFu41LHqJJRbKx-header'>days until the end of paid access</Trans>
              </span>
            </p>
          </div>
        )
      } else {
        return null
      }
    }
  }

  return <>{switchSubscription()}</>
}

export default SubscriptionStatus
