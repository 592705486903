import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Button } from 'components/Button'
import { Colors } from 'constants/colors'

export const ScrollPolicyBox = styled(Box)`
  max-height: 100%;
  overflow-y: scroll;
  padding-right: 80px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: 'transparent';
  }

  &::-webkit-scrollbar-thumb {
    background: ${Colors.infoBgColor}; // Цвет самого скроллбара (ползунка)
    border-radius: 20px;
  }
`

export const Title = styled.h2`
  font-family: Rubik;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;

  color: ${Colors.textColor};
`

export const Subtitle = styled.h3`
  margin-top: 20px;

  font-family: Rubik;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

  color: ${Colors.textColor};
`

export const Text = styled.p`
  margin-top: 20px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

  color: ${Colors.textColor};
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;

  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  color: ${Colors.textColor};
  padding-left: 1.5em;
  list-style: circle;
`

export const ListItem = styled.li`
  list-style-type: circle !important;
  /* padding-left: 1em; /* Добавляем отступ для маркеров */
`

export const Span = styled.span`
  font-weight: 700;
`

export const PolicyButton = styled(Button)`
  margin-top: 40px;
  @media (width < 744px) {
    width: 100%;
  }
`

export const MailLink = styled.a`
  margin-left: 0.6rem;
  color: ${Colors.blueAccent};

  transition: color 250ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover,
  &:focus {
    color: ${Colors.activeBlueColor};
  }
`
