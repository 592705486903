import { useAppDispatch } from 'store/hooks'
import { notificationActions } from 'store/notification'
import { v4 as uuidv4 } from 'uuid'

export const useNotification = () => {
  const dispatch = useAppDispatch()

  const addNotification = (label, type) => {
    dispatch(notificationActions.add({ id: uuidv4(), label, type }))
  }

  const addSuccess = (message) => {
    addNotification(message, 'success')
  }

  const addInfo = (message) => {
    addNotification(message, 'info')
  }

  const addAttention = (message) => {
    addNotification(message, 'attention')
  }

  const addAlert = (message) => {
    addNotification(message, 'alert')
  }

  return { addSuccess, addInfo, addAttention, addAlert }
}
