import { User } from '../auth.state'
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getUserData = createAsyncThunk<User>('auth/getUserData', async (_, thunkAPI) => {
  try {
    const response = await axios.get('/user')

    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message)
  }
})
