import { FC, ReactNode } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { patientSelector } from 'store/patient/patient.selector'
import { useAppSelector } from 'store/hooks'

interface Props {
  children: ReactNode
  redirect: string
}

const PrivatePatientRoute: FC<Props> = ({ children, redirect }) => {
  const isLoggedIn = useAppSelector(patientSelector.selectIsLoggedIn)
  const { id } = useParams()

  if (!isLoggedIn) {
    return <Navigate to={`${redirect}/${id}`} />
  }

  return <>{children}</>
}

export default PrivatePatientRoute
