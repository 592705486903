import React, { ReactNode, useEffect, useState } from 'react'
import { ModalWindow, Overlay, StyledCloseBtn } from './PrivacyPolicyPopup.styled'
import { animated, useSpring } from 'react-spring'

interface PolicyProps {
  toggleModal: () => void
  children: ReactNode
}

const PrivacyPolicyPopup: React.FC<PolicyProps> = ({ toggleModal, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = () => {
    setIsOpen(false)
  }

  const closeModalOnOverlay = (e) => {
    if (e.target === e.currentTarget) {
      closeModal()
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsOpen(true), 100)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    if (!isOpen) {
      // Задержка, чтобы позволить анимации завершиться перед закрытием
      const timeoutId = setTimeout(() => {
        toggleModal()
      }, 300)

      return () => clearTimeout(timeoutId)
    }
  }, [isOpen, toggleModal])

  useEffect(() => {
    const closeModalOnEscPush = (e) => {
      const KEY_CODE_ESCAPE = 'Escape'

      if (e.code === KEY_CODE_ESCAPE) {
        closeModal()
      }
    }

    window.addEventListener('keydown', closeModalOnEscPush)

    return () => window.removeEventListener('keydown', closeModalOnEscPush)
  }, [toggleModal])

  const animation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? 'translateY(0)' : 'translateY(-100%)',
    config: { duration: 300 },
  })

  return (
    <Overlay onClick={closeModalOnOverlay}>
      <animated.div style={animation}>
        <ModalWindow>
          <StyledCloseBtn onClick={closeModal} />
          {children}
        </ModalWindow>
      </animated.div>
    </Overlay>
  )
}

export default PrivacyPolicyPopup
