import { MenuBtn, ProfileCard, ProfileImg } from './Header.styled'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useEffect, useState } from 'react'

import { Colors } from 'constants/colors'
import { Icon } from 'components/Icon'
import { LanguagesSelector } from 'components/LanguagesSelector'
import { Logo } from '../Logo'
import { Menu } from 'components/Menu'
import { Popup } from '../Popup'
import { SubscriptionExpiredPopup } from 'components/SubscriptionExpiredPopup'
import { SubscriptionStatus } from 'components/SubscriptionStatus'
import { Trans } from '@lingui/macro'
import { authSelector } from 'store/auth'
import { getUserData } from 'store/auth/operations/getUserData.operation'
import useConditionalNavigate from 'hooks/useConditialNavigate'
import { useSubscription } from 'hooks/useSubscription'

const Header = () => {
  const clinic = useAppSelector(authSelector.selectClinic)
  const dispatch = useAppDispatch()
  const { isLoading, isExpiredPopup, subscription, handleRequestSubscription } = useSubscription()
  const [isMenu, setIsMenu] = useState(false)
  const navigate = useConditionalNavigate()
  const toggleMenu = () => {
    setIsMenu(!isMenu)
  }

  useEffect(() => {
    if (!clinic) {
      dispatch(getUserData())
    }
  }, [clinic, dispatch])

  return (
    <>
      <div className='cursor-pointer' onClick={() => navigate('/')}>
        <Logo />
      </div>
      <div className='flex items-center gap-[8px]'>
        <SubscriptionStatus subscription={subscription} isLoading={isLoading} />
        <LanguagesSelector />
        <ProfileCard onClick={() => navigate('/settings')}>
          <div style={{ width: '34px', height: '34px', overflow: 'hidden', borderRadius: '50%' }}>
            {clinic?.details?.logo ? (
              <ProfileImg src={clinic.details.logo} alt='Avatar' />
            ) : (
              <Icon name='avatar' size={34} />
            )}
          </div>
          <p>{clinic?.name || 'D. doctor'}</p>
        </ProfileCard>
        <MenuBtn onClick={toggleMenu}>
          <Icon name='menu' color={Colors.blueAccent} />
          <Trans id='c8FjNzq1B9F9TSBxYJUVNN-home'>Menu</Trans>
        </MenuBtn>
      </div>
      {isMenu && (
        <Popup toggleModal={toggleMenu}>
          <Menu toggleMenu={toggleMenu} />
        </Popup>
      )}
      <SubscriptionExpiredPopup
        subscription={subscription}
        isExpiredPopup={isExpiredPopup}
        handleRequestSubscription={handleRequestSubscription}
      />
    </>
  )
}

export default Header
