import { RootState } from 'store'
import { createSelector } from '@reduxjs/toolkit'
import { currencyType } from 'constants/currency'
import { toothNumbers } from 'constants/toothNumber'

class AuthSelector {
  selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn
  selectIsExpiredPopup = (state: RootState) => state.auth.isExpiredPopup
  selectIsExpired = (state: RootState) => state.auth.isExpired
  selectSubscription = (state: RootState) => state.auth.subscription
  selectIsLoadingSubscription = (state: RootState) => state.auth.isLoadingSubscription
  selectTokens = (state: RootState) => state.auth.tokens
  selectClinicId = (state: RootState) => state.auth.user?.hospitalId
  selectClinic = (state: RootState) => state.auth.user?.hospital
  selectUser = (state: RootState) => state.auth.user
  selectAccountCurrency = (state: RootState) => state.auth.user.currency
  selectAccountCurrencyType = createSelector(
    (state: RootState) => state.auth.user.currency,
    (currency) => currencyType[currency],
  )
  selectUserName = (state: RootState) => state.auth.user.name
  selectToothType = (state: RootState) => state.auth.user.toothType
  selectTypedTooth = createSelector(this.selectToothType, (type) => {
    return (toothId) => {
      const typedNumbers = toothNumbers[type]
      if (!typedNumbers) {
        return toothId
      }

      return typedNumbers[toothId]
    }
  })
}

export const authSelector = new AuthSelector()
