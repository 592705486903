import * as yup from 'yup'

import { t } from '@lingui/macro'

export const useValidationCompleteRegistrationSchema = () =>
  yup.object({
    clinic: yup
      .string()
      .trim()
      .required(t({ id: '"g3jkcwF4W7vYGrpgkPnyiq-auth"', message: 'Clinic is required' })),
    password: yup
      .string()
      .trim()
      .min(
        8,
        t({
          id: '6F5hBktwUwdbVHZsxFeDpd-auth',
          message: 'Password should be of minimum 8 characters length',
        }),
      )
      .required(t({ id: 'oxPrZhG9vZD5j7oJpEFTBv-auth', message: 'Password is required' }))
      .matches(
        /[0-9]/,
        t({
          id: 'kcUDbuNpfWeTGuTFecT1fa-auth',
          message:
            'The password must contain at least one capital letter, a number and a special character as A, 1, &.',
        }),
      )
      .matches(
        /[a-z]/,
        t({
          id: 'i9i3MM46bc4s9mBVXC1Hmx-auth',
          message:
            'The password must contain at least one capital letter, a number and a special character as A, 1, &.',
        }),
      )
      .matches(
        /[A-Z]/,
        t({
          id: 'wFjeDAiB9s4KTqcBxSYtQc-auth',
          message:
            'The password must contain at least one capital letter, a number and a special character as A, 1, &.',
        }),
      ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t({ id: '682u1bbYCRdfe9u5wGCzRH-auth', message: 'Password must Confirm' }),
      ),
  })
