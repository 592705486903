import { FC, useCallback, useEffect, useState } from 'react'
import {
  IDefaultComponent,
  ILanguage,
  ITranslatorContext,
  ITranslatorProvider,
  IlanguageObject,
  LANGUAGES,
} from './TranslatorProvider.types'
import { detect, fromNavigator } from '@lingui/detect-locale'

import { I18nProvider } from '@lingui/react'
import LocalStorageService from 'helpers/localStorageService'
import { authSelector } from 'store/auth'
import { createContext } from 'use-context-selector'
import { messages as czMessages } from '../../locales/cz/messages'
import { messages as enMessages } from '../../locales/en/messages'
import { i18n } from '@lingui/core'
import { useAppSelector } from 'store/hooks'
import { useCustomRouter } from 'hooks/useCastomRouter'

export const DEFAULT_LOCALE = 'cz'
export const TranslatorContext = createContext<Partial<ITranslatorContext>>({})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getValidLanguage = (language: any) => {
  if (language && (Object.keys(LANGUAGES).includes(language) || language === 'ukr'))
    return language === 'ukr' ? 'uk' : language

  return null
}

export const DefaultComponent: FC<IDefaultComponent> = ({ messages, translation, id }) => {
  return (
    <>
      {translation
        ? translation
        : typeof ((messages?.[id] || '') as string) === 'string'
        ? ((messages?.[id] || '') as string).replace(/<.*>|\{.*\}/, '')
        : ((messages?.[id] || '') as string)}
    </>
  )
}

const TranslatorProvider: FC<ITranslatorProvider> = ({ children }) => {
  const { locale: pathLocale } = useCustomRouter()
  const [language, setLanguage] = useState<ILanguage>(DEFAULT_LOCALE)
  const [languages, setLanguages] = useState<IlanguageObject[]>(Object.values(LANGUAGES))
  const [firstLoad, setFirstLoad] = useState(true)
  const [messages, setMessages] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const user = useAppSelector(authSelector.selectUser)

  const loadLocale = useCallback(async (locale: ILanguage) => {
    let messages
    if (locale === 'en') {
      messages = enMessages
    }
    if (locale === 'cz') {
      messages = czMessages
    }
    // if (locale === 'uk') {
    //   messages = ukMessages
    // }
    // if (locale === 'de') {
    //   messages = deMessages
    // }

    setMessages(messages)
    setLanguage(locale)
    i18n.load(locale, messages)
    i18n.activate(locale)
    setLanguages((prev) => [LANGUAGES[locale], ...prev.filter((item) => item.value !== locale)])
    LocalStorageService.setItem('lng', locale)
  }, [])

  const init = useCallback(async () => {
    if (typeof window === 'undefined' || !firstLoad) return

    const localLanguage = await LocalStorageService.getItemAsync('lng')
    const pathLocaleValid = getValidLanguage(pathLocale)
    const localLanguageValid = getValidLanguage(localLanguage)
    const navigatorLanguageValid = getValidLanguage(fromNavigator())

    const detectedLanguage = detect(
      pathLocaleValid,
      localLanguageValid,
      navigatorLanguageValid,
      DEFAULT_LOCALE,
    )

    setFirstLoad(false)
    if (
      detectedLanguage &&
      (Object.keys(LANGUAGES).includes(detectedLanguage) || detectedLanguage === 'ukr')
    ) {
      // loadLocale(detectedLanguage === 'ukr' ? 'uk' : (detectedLanguage as ILanguage))
      loadLocale(detectedLanguage as ILanguage)
    } else {
      loadLocale('en')
    }
  }, [loadLocale, pathLocale, firstLoad])

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    if (!isFirstLoad) return

    if (user && user.metadata.language !== language) {
      loadLocale(user.metadata.language)
      setIsFirstLoad(false)
    }
  }, [user, language, loadLocale, isFirstLoad])

  const value: ITranslatorContext = {
    languages,
    language,
    loadLocale,
  }

  if (!messages) return <></>

  return (
    <I18nProvider
      i18n={i18n}
      defaultComponent={(props) => <DefaultComponent {...props} messages={messages} />}
    >
      <TranslatorContext.Provider value={value}>{children}</TranslatorContext.Provider>
    </I18nProvider>
  )
}

export default TranslatorProvider
