import { CompleteRegistrationForm } from 'modules/login/components/CompleteRegistrationForm'
import { Trans } from '@lingui/macro'
import { authSelector } from 'store/auth'
import { useAppSelector } from 'store/hooks'

const CompleteRegistrationPopup = () => {
  const user = useAppSelector(authSelector.selectUser)

  if (!user) return null

  return (
    <>
      {user.isNew ? (
        <div className='absolute inset-0 bg-[#24274166] z-[1000000]'>
          <div className='flex flex-col items-center justify-center h-full'>
            <div className='flex flex-col items-center justify-center p-[40px] bg-white rounded-[40px]'>
              <h2 className='text-[32px] font-rubik font-bold text-textColor'>
                <Trans id='kRTe8jTe37jcr8yZPiZpKd-auth'>Complete Registration</Trans>
              </h2>
              <p className='text-textColor mt-[12px] text-[16px] font-rubik'>
                <Trans id='29qePoEob8vJNaPvYSrW7w-auth'>
                  Please complete your registration by filling in the required fields
                </Trans>
              </p>
              <CompleteRegistrationForm />
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default CompleteRegistrationPopup
