import styled from '@emotion/styled'
import { CloseBtn } from 'components/CloseBtn'
import { Colors } from 'constants/colors'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0;
  background-color: rgba(24, 27, 41, 0.6);
  overflow-y: scroll;
  z-index: 1200;
`

export const ModalWindow = styled.div`
  position: relative;
  /* margin: 50px 0; */
  margin-bottom: 50px;
  width: 898px;
  height: calc(100vh - 100px);
  border-radius: 40px;
  padding: 80px 0 80px 80px;
  background-color: ${Colors.white};

  @media (width < 1200px) {
    max-width: 898px;
    width: 100%;
    padding: 40px;
    border-radius: 40px 40px 0 0;
    margin-bottom: 0;
    height: calc(100vh - 50px);
  }
  @media (width < 744px) {
    width: 100%;
    padding: 32px;
  }
`
export const StyledCloseBtn = styled(CloseBtn)`
  position: absolute;

  right: 15px;
  top: 15px;
`
