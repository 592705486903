import { Fade, Modal } from '@mui/material'

import { Button } from 'components/Button'
import { FC } from 'react'
import { ISubscription } from 'store/auth/auth.state'
import { Trans } from '@lingui/macro'
import { authActions } from 'store/auth'
import classNames from 'classnames'
import { useAppDispatch } from 'store/hooks'

interface SubscriptionExpiredPopupProps {
  subscription: ISubscription
  isExpiredPopup: boolean
  handleRequestSubscription: () => void
}

const SubscriptionExpiredPopup: FC<SubscriptionExpiredPopupProps> = ({
  isExpiredPopup,
  subscription,
  handleRequestSubscription,
}) => {
  const dispatch = useAppDispatch()

  // if (!subscription) return null

  return (
    <Modal open={isExpiredPopup} onClose={() => dispatch(authActions.toggleExpiredPopup())}>
      <Fade in={isExpiredPopup} timeout={400}>
        <div
          className={classNames(
            'absolute left-[50%] top-[50%] transform -translate-x-1/2 -translate-y-1/2',
            'flex flex-col gap-[40px] items-center justify-center p-[40px] bg-white rounded-[40px] w-fit',
          )}
        >
          <div>
            <h2 className='text-[32px] text-center font-rubik font-bold text-textColor'>
              Attention!
            </h2>
            <p className='text-textColor text-center mt-[12px] text-[16px] font-rubik'>
              <span>
                {subscription?.type === 'paid' ? (
                  <Trans id='85hzHLvJ2TJpWTGM2dPXXo-subscription'>Paid</Trans>
                ) : (
                  <Trans id='9MiFTmQb5R6SVGraFTbeTB-subscription'>Demo</Trans>
                )}
              </span>
              <Trans id='q2UsF6kYp8n7zpc4QgHm7z-subscription'>period is ended</Trans>
            </p>
          </div>
          <div
            className={classNames(
              'rounded-[16px]  p-[24px] flex justify-center flex-col gap-[10px] bg-white-dark w-[588px]',
              'font-rubik text-[16px] text-textColor leading-6 text-center',
            )}
          >
            <p>
              <Trans id='1KYDrD8DaGvkFQBu1MojLG-subscription'>
                Your account has been switched to preview mode.
              </Trans>
            </p>
            <p>
              <Trans id='rnaYfn4zCdjrwJdikxATKd-subscription'>
                To gain full access, you need to subscribe for continuous use of the service.
              </Trans>
            </p>
            <p>
              <Trans id='2Cy3SwWh3SP2uJbsTnZBN1-subscription'>
                Please contact us to extend your access.
              </Trans>
            </p>
          </div>
          <Button color='blue' className='w-full' onClick={handleRequestSubscription}>
            <Trans id='gfHhfQDJns8iJrwkVZpAeo-subscription'>Write to us</Trans>
          </Button>
        </div>
      </Fade>
    </Modal>
  )
}

export default SubscriptionExpiredPopup
