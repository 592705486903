import { Route, Routes } from 'react-router-dom'

import { CompleteRegistrationPopup } from 'components/CompleteRegistrationPopup'
import { MainLayout } from '../../layouts/mainLayout'
import PrivatePatientRoute from './components/PrivatePatientRoute'
import PrivateRoute from './components/PrivateRoute'
import RestrictedPatientRoute from './components/RestrictedPatientRoute'
import RestrictedRoute from './components/RestrictedRoute'
import { routes } from 'constants/routes'

const Router = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        {routes.map(
          ({ component: Component, private: isPrivate, restricted, redirect, patient, path }) => {
            let ChildElement = <Component />

            if (patient && restricted) {
              ChildElement = (
                <RestrictedPatientRoute redirect={redirect}>
                  <Component />
                </RestrictedPatientRoute>
              )
            }

            if (patient && isPrivate) {
              ChildElement = (
                <PrivatePatientRoute redirect={redirect}>
                  <CompleteRegistrationPopup />
                  <Component />
                </PrivatePatientRoute>
              )
            }

            if (isPrivate && !patient) {
              ChildElement = (
                <PrivateRoute redirect={redirect}>
                  <CompleteRegistrationPopup />
                  <Component />
                </PrivateRoute>
              )
            }
            if (restricted && !patient) {
              ChildElement = (
                <RestrictedRoute redirect={redirect}>
                  <Component />
                </RestrictedRoute>
              )
            }

            return <Route path={path} element={ChildElement} key={path} />
          },
        )}
      </Route>
    </Routes>
  )
}

export default Router
