import { authActions, authSelector } from 'store/auth'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { getUserSubscription } from 'store/auth/operations/get-user-subscription.operation'
import { sendRequestSubscription } from 'store/auth/operations/sendRequestSubscription.operation'
import { t } from '@lingui/macro'
import { useEffect } from 'react'
import { useNotification } from './useNotification'

export const useSubscription = () => {
  const subscription = useAppSelector(authSelector.selectSubscription)
  const isExpiredPopup = useAppSelector(authSelector.selectIsExpiredPopup)
  const isLoading = useAppSelector(authSelector.selectIsLoadingSubscription)

  const dispatch = useAppDispatch()
  const { addSuccess, addAlert } = useNotification()

  useEffect(() => {
    dispatch(getUserSubscription())
  }, [dispatch])

  const handleRequestSubscription = async () => {
    try {
      const res = await dispatch(sendRequestSubscription())

      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(authActions.toggleExpiredPopup())
        addSuccess(
          t({
            id: 'ei2KyNotcFChZ76AAvCPHi-subscription',
            message: 'Subscription request sent successfully. We will contact you shortly.',
          }),
        )
      } else {
        addAlert(
          t({
            id: 'hf3CW798orFNJ9MNvQguLs-subscription',
            message: 'Failed to send subscription request. Please try again later.',
          }),
        )
      }
    } catch (error) {
      addAlert(
        t({
          id: 'hf3CW798orFNJ9MNvQguLs-subscription',
          message: 'Failed to send subscription request. Please try again later.',
        }),
      )
    }
  }

  return {
    isLoading,
    isExpiredPopup,
    subscription,
    handleRequestSubscription,
  }
}
