import { Trans } from '@lingui/macro'
import { PrivacyPolicyInfo } from '../PrivacyPolicyInfo'
import { PrivacyPolicyPopup } from '../PrivacyPolicyPopup'
import { PolicyBtn, PrivacyBox } from './PrivacyPolicy.styled'
import { useState } from 'react'

const PrivacyPolicy = () => {
  const [isPolicy, setIsPolicy] = useState(false)

  const togglePolicy = () => {
    setIsPolicy(!isPolicy)
  }

  return (
    <>
      <PrivacyBox>
        <p>
          <Trans id='5FWpXEAXKPiWTKJffGSjSK-auth'>By clicking Sign up you agree with the</Trans>
        </p>
        <PolicyBtn onClick={togglePolicy}>
          <Trans id='uSQJ6eH43ThUFT8FufwHR8-auth'>privacy policy</Trans>
        </PolicyBtn>
      </PrivacyBox>
      {isPolicy && (
        <PrivacyPolicyPopup toggleModal={togglePolicy}>
          <PrivacyPolicyInfo onClick={togglePolicy} />
        </PrivacyPolicyPopup>
      )}
    </>
  )
}

export default PrivacyPolicy
