import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'

import { AuthState } from './auth/auth.state'
import { PatientState } from './patient/patient.types'
import { authReducer } from './auth'
import { configureStore } from '@reduxjs/toolkit'
import { enableMapSet } from 'immer'
import { homeReducer } from './home'
import { notificationReducer } from './notification'
import { patientReducer } from './patient/patient.slice'
import { settingsReducer } from './settings/settings.slice'
import storage from 'redux-persist/lib/storage'
import { treatmentPlanReducer } from './treatmentPlan/treatmentPlan.slice'
import { treatmentReducer } from './treatment/treatment.slice'

const persistConfig = {
  key: 'user',
  storage,
  whiteList: ['tokens'],
  blacklist: ['user'],
}
const patientPersistConfig = {
  key: 'patient',
  storage,
  whitelist: ['patient'], // Здесь укажите поля, которые нужно сохранять
}

enableMapSet()

export const store = configureStore({
  reducer: {
    home: homeReducer,
    patient: persistReducer<PatientState>(patientPersistConfig, patientReducer),
    auth: persistReducer<AuthState>(persistConfig, authReducer),
    notifications: notificationReducer,
    treatment: treatmentReducer,
    settings: settingsReducer,
    treatmentPlan: treatmentPlanReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
