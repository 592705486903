import { changeAccountSettings } from './operations/change-account-settings.operation'
import { changePasswordOperation } from './operations/change-password.operation'
import { completeRegistration } from './operations/completeRegistration.operation'
import { createSlice } from '@reduxjs/toolkit'
import { getUserData } from './operations/getUserData.operation'
import { getUserSubscription } from './operations/get-user-subscription.operation'
import { initialState } from './auth.state'
import { refreshTokenOperation } from './operations/refresh-tokens.operation'
import { refreshUserOperation } from './operations/refresh-user.operation'
import { signInOperation } from './operations/signIn.operation'
import { signUpOperation } from './operations/sign-up.operation'
import { verifyEmailOperation } from './operations/verify-email.operation'

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoggedIn = true
    },
    logout: (state) => {
      state.isLoggedIn = false
    },
    toggleExpiredPopup: (state) => {
      state.isExpiredPopup = !state.isExpiredPopup
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUpOperation.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(signInOperation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(signInOperation.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(signInOperation.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoggedIn = true
        state.tokens = action.payload.tokens
        state.user = action.payload.user
      })
      .addCase(changePasswordOperation.fulfilled, (state, action) => {
        state.tokens = action.payload.tokens
      })
      .addCase(refreshTokenOperation.fulfilled, (state, action) => {
        state.tokens = action.payload
      })
      .addCase(refreshTokenOperation.rejected, (state) => {
        state.tokens.accessToken = null
        state.tokens.refreshToken = null
        state.isLoggedIn = false
      })
      .addCase(verifyEmailOperation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(verifyEmailOperation.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(verifyEmailOperation.fulfilled, (state, action) => {
        state.isLoading = false
        state.tokens = action.payload.tokens
        state.user = action.payload.user
      })
      .addCase(refreshUserOperation.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(refreshUserOperation.rejected, (state) => {
        state.isLoading = false
        state.isLoggedIn = false
      })
      .addCase(changeAccountSettings.fulfilled, (state, action) => {
        state.user.toothType = action.payload.toothType
      })
      .addCase(completeRegistration.fulfilled, (state, action) => {
        state.user = action.payload.user
      })
      .addCase(getUserSubscription.pending, (state) => {
        state.isLoadingSubscription = true
      })
      .addCase(getUserSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload

        if (!action.payload?.id) {
          state.isExpired = true
          state.isExpiredPopup = true
        }

        if (new Date(action.payload.endAt).getTime() < new Date().getTime()) {
          state.isExpired = true
          state.isExpiredPopup = true
        }
        state.isLoadingSubscription = false
      })
      .addCase(getUserSubscription.rejected, (state) => {
        state.isLoadingSubscription = true
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.user = action.payload
      })
  },
})

export const authActions = authSlice.actions
export const authReducer = authSlice.reducer
