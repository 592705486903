import { Trans, t } from '@lingui/macro'

import { Button } from 'components/Button'
import { InputField } from 'components/form/InputField'
import { PrivacyPolicy } from '../PrivacyPolicy'
import { completeRegistration } from 'store/auth/operations/completeRegistration.operation'
import { useAppDispatch } from 'store/hooks'
import { useFormik } from 'formik'
import { useNotification } from 'hooks/useNotification'
import { useValidationCompleteRegistrationSchema } from './CompleteRegistrationForm.schema'

const CompleteRegistrationForm = () => {
  const validationSchema = useValidationCompleteRegistrationSchema()
  const { addAlert, addSuccess } = useNotification()
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      clinic: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const credentials = {
          clinic: values.clinic,
          password: values.password,
        }
        const res = await dispatch(completeRegistration(credentials))

        if (res.meta.requestStatus === 'fulfilled') {
          resetForm()
          addSuccess(
            t({
              id: 'sYYrkZ8drGGSWkxZoMZbmT-auth',
              message: 'Registration completed successfully',
            }),
          )
        }
      } catch (error) {
        addAlert(error.message)
      }
    },
  })

  return (
    <form className='w-full mt-[40px]' onSubmit={formik.handleSubmit}>
      <InputField
        label={t({ id: 'aqHp1SZPBPBCuw6SG7HjAH-auth', message: 'Clinic' })}
        fieldProps={formik.getFieldProps('clinic')}
        error={formik.errors.clinic}
        touched={formik.touched.clinic}
        placeholder={t({ id: 'rKz4hP5uLWnwj1XMve27of-auth', message: 'Clinic' })}
      />
      <InputField
        type='password'
        label={t({ id: 'iRdGahGdEnTz2D4U5Yeqaj-auth', message: 'Password' })}
        fieldProps={formik.getFieldProps('password')}
        error={formik.errors.password}
        touched={formik.touched.password}
        placeholder={t({ id: '2HCpeUjwyNixEH2M75q2Zy-auth', message: 'Password' })}
      />
      <InputField
        type='password'
        label={t({ id: 'cNfZzFitvBWEFuXvrV2HzG-auth', message: 'Confirm password' })}
        fieldProps={formik.getFieldProps('confirmPassword')}
        error={formik.errors.confirmPassword}
        touched={formik.touched.confirmPassword}
        placeholder={t({ id: 'nQSeKX9r5jCQognseSHBKm-auth', message: 'Confirm password' })}
      />
      <Button className='mt-[40px]' type='submit' color='blue' style={{ width: '100%' }}>
        <Trans id='3T3yoUdZFJFThB3qCLbbAH-auth'>Sign Up</Trans>
      </Button>
      <PrivacyPolicy />
    </form>
  )
}

export default CompleteRegistrationForm
