import { ISubscription } from '../auth.state'
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getUserSubscription = createAsyncThunk<ISubscription>(
  'auth/getUserSubscription',
  async (_, thunkAPI) => {
    try {
      const res = await axios.get('/subscriptions/user')

      return res.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)
