import { Tokens, User } from '../auth.state'

import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

interface VerifyResponse {
  tokens: Tokens
  user: User
}

export const verifyEmailOperation = createAsyncThunk<VerifyResponse, string>(
  'auth/verify-email',
  async (token, thunkAPI) => {
    try {
      const response = await axios.get('/auth/verify', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)
